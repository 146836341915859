import React, { useContext, useEffect, useState } from 'react'
import { Link, graphql } from 'gatsby'
import {
  AdStarBlock,
  Category,
  GeneralSettings,
  SponsoredCategories
} from '../../../types'
import { IMenu } from '../../../graphql/entities/Menu'
import TopBanner from '../../../components/Banners/TopBanner/TopBanner'
import Content from '../../../components/Content/Content'
import Footer from '../../../components/Footer/Footer'
import Header from '../../../components/Header/Header'
import AwardsHeader from '../../../components/MuddyAwards/AwardsHeader'
import AwardsMenu from '../../../components/MuddyAwards/AwardsMenu'
import Breadcrumb from '../../../components/organisms/breadcrumb/Breadcrumb'
import PageMeta from '../../../components/PageMeta/PageMeta'
import MuddyCategoryBlocks from '../../../components/MuddyAwards/MuddyCategoryBlocks'
import BlocksWrappers from '../../../components/Blocks/BlocksWrappers'
import { MuddyAwardsContext, MuddyAwardsStates } from '../../../lib/utils'
import Leaderboard from '../../../components/Banners/Leaderboard/Leaderboard'

export const query = graphql`
  query {
    wpgraphql {
      generalSettings {
        title
        siteId
        egSettingTwitter
        url
      }
      awardsCategories {
        title
        image
        description
        id
      }
      sponsoredCategories {
        slug
        title
      }
      adStarCodes {
        id
        adslot
      }
      awardsMenu: menu(id: "Awards", idType: NAME) {
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
      category(id: "muddy-awards", idType: SLUG) {
        sponsoredBy
        sponsoredLogo
      }
    }
  }
`

interface AwardsCategoriesProps {
  data: {
    wpgraphql: {
      generalSettings: GeneralSettings
      adStarCodes: AdStarBlock[]
      awardsMenu: {
        menuItems: {
          nodes: IMenu[]
        }
      }
      category: Category
      awardsCategories: {
        id: string
        title: string
        image: string
        description: string
      }[]
      sponsoredCategories: SponsoredCategories[]
    }
  }
}

const AwardsCategories = ({
  data: {
    wpgraphql: {
      generalSettings,
      adStarCodes,
      awardsMenu: {
        menuItems: { nodes: subMenu }
      },
      awardsCategories,
      category,
      sponsoredCategories
    }
  }
}: AwardsCategoriesProps) => {
  const [trendingCat, setTrendingCat] = useState<
    SponsoredCategories | undefined
  >()
  const AwardsContext = useContext(MuddyAwardsContext)

  useEffect(() => {
    const trending = sponsoredCategories.filter(c => c.slug === 'SPOTLIGHT')

    trending.length > 0 && setTrendingCat(trending[0])
  }, [sponsoredCategories])

  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: 'Muddy Stilettos Awards 2024',
          description:
            'The Muddy Stilettos Awards are the most coveted awards for independent lifestyle businesses across 28 counties, and totally FREE to enter!',
          image:
            'https://muddystilettos.co.uk/images/awards/Awards_Badge_2024.gif'
        }}
      />
      <Header />
      <TopBanner ads={adStarCodes} pageType={`awards`} />
      <Leaderboard adSlot={'TopLB'} />
      {category && (
        <AwardsHeader category={category} generalSettings={generalSettings} />
      )}
      {subMenu && (
        <AwardsMenu subMenu={subMenu} generalSettings={generalSettings} />
      )}
      <div>
        <Content>
          <Breadcrumb
            postType={`awards`}
            postTitle={'/ Categories'}
            postURI={'/awards/categories'}
          />
          <h1 dangerouslySetInnerHTML={{ __html: 'Categories' }} />
          <div style={{ textAlign: 'center' }}>
            {AwardsContext === MuddyAwardsStates.VOTING ? (
              <>
                <>
                  <p>
                    Voting is now open! Support your favourite local businesses
                    across 21 categories.
                  </p>
                </>
              </>
            ) : (
              <>
                <p>
                  The Regional Awards votes are now closed. Watch this space!
                  Regional Winners will be announced 2nd May 2024.
                </p>
                {/* <p>
                  Check out the Live results with our undulating Voting
                  Cocktails <Link to={`/awards/live-results/`}>here</Link>
                </p> */}
              </>
            )}
          </div>
        </Content>
        <MuddyCategoryBlocks categories={awardsCategories} />
      </div>
      <div>
        {trendingCat && (
          <BlocksWrappers
            slug={trendingCat?.slug || ''}
            title={trendingCat?.title || ''}
            index={1}
            awardsBlock={true}
          />
        )}
      </div>
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default AwardsCategories
