import React, { useContext } from 'react'
import _ from 'lodash'
import * as CategoryStyles from './MuddyCategoryBlocks.module.scss'
import { MuddyAwardsContext, MuddyAwardsStates } from '../../../lib/utils'

interface MuddyCategoryBlocksProps {
  categories: {
    title: string
    description: string
    image: string
    id: string
  }[]
}

const MuddyCategoryBlocks: React.FC<MuddyCategoryBlocksProps> = ({
  categories
}) => {
  const sortedCategories = _.sortBy(categories, 'title')
  const AwardsContext = useContext(MuddyAwardsContext)

  return (
    <div className={CategoryStyles.Wrapper}>
      <div className={CategoryStyles.Blocks}>
        {sortedCategories.map(c => (
          <div className={CategoryStyles.Block} key={c.title}>
            {c.image && (
              <div className={CategoryStyles.Block_Img}>
                <img src={c.image ?? ''} alt={''} />
              </div>
            )}
            <div className={CategoryStyles.Block_Text}>
              <span
                className={CategoryStyles.Title}
                dangerouslySetInnerHTML={{ __html: c.title }}
              />
              <span
                className={CategoryStyles.Description}
                dangerouslySetInnerHTML={{ __html: c.description }}
              ></span>
              {AwardsContext === MuddyAwardsStates.NOMINATIONS && (
                <a
                  href={`/awards/nominate?c=${c.id}`}
                  className={CategoryStyles.Button}
                >
                  Nominate
                </a>
              )}
              {AwardsContext === MuddyAwardsStates.VOTING && (
                <a
                  href={`/awards/vote?c=${c.id}`}
                  className={CategoryStyles.Button}
                >
                  Vote
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MuddyCategoryBlocks
