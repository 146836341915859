// extracted by mini-css-extract-plugin
export var Block = "MuddyCategoryBlocks-module--Block--e21e9";
export var Block_Img = "MuddyCategoryBlocks-module--Block_Img--80e2c";
export var Block_Text = "MuddyCategoryBlocks-module--Block_Text--09c61";
export var Blocks = "MuddyCategoryBlocks-module--Blocks--b9068";
export var Button = "MuddyCategoryBlocks-module--Button--b2798";
export var Description = "MuddyCategoryBlocks-module--Description--3ceb4";
export var DoubleMargin = "MuddyCategoryBlocks-module--DoubleMargin--f4260";
export var Padding = "MuddyCategoryBlocks-module--Padding--fcdb8";
export var ReadMoreButton = "MuddyCategoryBlocks-module--ReadMoreButton--fedf9";
export var SingleMargin = "MuddyCategoryBlocks-module--SingleMargin--d12d1";
export var Wrapper = "MuddyCategoryBlocks-module--Wrapper--3c3dc";